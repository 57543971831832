import React, { ReactNode, useMemo, useState } from "react";
import { useHover } from "react-aria";

import { styled, css, Button, IconButton, Drawer, Text } from "@puzzle/ui";
import { External, NavGetStarted, Crown, SpinnerStatic } from "@puzzle/icons";

import { useActiveCompany } from "components/companies";

import Navigation from "./Navigation";
import ProfileMenu from "./ProfileMenu";
import CompanyMenu from "./CompanyMenu";
import Minimizer from "./Minimizer";
import useNavSections from "./useNavSections";
import Analytics from "lib/analytics";
import useAppRouter from "lib/useAppRouter";
import { differenceInCalendarDays } from "date-fns";
import { NavigationItemContent } from "./Navigation/types";
import { TrialReminder } from "./TrialReminder";
import useMonetization from "components/monetization/useMonetization";
import { now, parseDate } from "@internationalized/date";
import { PricingPlanNames } from "components/monetization/types";
import { Box, S } from "ve";

export const DRAWER_WIDTH = 280;
export const MINIMIZED_DRAWER_WIDTH = 72;
export const PADDING = 16;

const baseStyles = css({
  position: "relative",
  transition: "width 0.15s ease-in-out",
  height: "100vh",
  backgroundColor: "$mauve800",
  boxShadow: `0.5px 0px 0px $colors$mauve400`,

  variants: {
    minimized: {
      true: {
        width: MINIMIZED_DRAWER_WIDTH,
      },
      false: {
        width: DRAWER_WIDTH,
      },
    },
  },

  "@media print": {
    display: "none",
  },
});

const OverlayDrawer = styled(Drawer, baseStyles, {
  padding: "0 !important",
  backgroundColor: "$mauve800 !important",

  // on top of triggering button
  zIndex: 2,
});

const PermanentDrawerContainer = styled("div", baseStyles);
const PrimaryDrawerContainer = styled("div", baseStyles);
const SecondaryDrawerContainer = styled("div", baseStyles, {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: MINIMIZED_DRAWER_WIDTH,
});

const PermanentDrawer = ({
  children,
  minimized,
  secondary,
}: {
  children?: ReactNode;
  minimized: boolean;
  secondary?: ReactNode;
}) => {
  return (
    <PermanentDrawerContainer
      style={{
        width: secondary
          ? DRAWER_WIDTH + MINIMIZED_DRAWER_WIDTH
          : minimized
          ? MINIMIZED_DRAWER_WIDTH
          : DRAWER_WIDTH,
      }}
    >
      {secondary && <SecondaryDrawerContainer>{secondary}</SecondaryDrawerContainer>}
      <PrimaryDrawerContainer minimized={minimized}>{children}</PrimaryDrawerContainer>
    </PermanentDrawerContainer>
  );
};

const Content = styled("div", {
  display: "grid",
  // Increased from 108 to 120px to accomodate Task icon when minimized
  // Increased from 120 to 135px to accomodate company button when there are multiple companies
  //   and not overlap with the scrollable content below
  gridTemplateRows: "135px 1fr min-content",
  gridTemplateColumns: "minmax(0, 1fr)",
  height: "100%",

  "> *": {
    padding: "$2 0 $2 $2",
  },
});

const NavigationContainer = styled("div", {
  display: "flex",
  flexGrow: "1",
  flexDirection: "column",
});

const ScrollContainer = styled("div", {
  overflow: "hidden",
  scrollbarGutter: "stable",

  "&:hover": {
    overflowY: "scroll",
  },
});

const HeaderContainer = styled("header", {
  paddingRight: "$2",
});

const Header = styled("div", {
  marginBottom: "$2",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  variants: {
    minimized: {
      true: {
        flexDirection: "column",
        alignItems: "start",
      },
      false: {},
    },
  },
});

const FreeTrialProgress = styled("div", {
  display: "flex",
  padding: "$1 $3 $1 $2",
  textVariant: "$bodyS",
  borderRadius: "$2",
  background: "$mauve700",
  color: "$gray400",
  position: "relative",

  "&:before": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "0%",
    height: "100%",
    borderRadius: "$2",
    content: "",
    background: "$white100",
  },
});

export const GettingStartedButton = React.memo(function GettingStartedButton({
  minimized,
  admin = false,
  disabled,
  onClick,
}: {
  minimized: boolean;
  admin?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <Navigation.Item
      minimized={minimized}
      disabled={disabled}
      item={{
        title: "Getting started",
        path: admin
          ? undefined
          : "https://www.notion.so/puzzlefin/Getting-Start-Tips-2b8abcffb5b74ad88baca9307cfe649f?pvs=4",
        icon: <NavGetStarted />,
        external: true,
        onClick: () => {
          Analytics.gettingStartedClicked();
          onClick?.();
        },
      }}
      css={
        minimized
          ? undefined
          : {
              "&:hover": {
                svg: {
                  opacity: 1,
                },
              },
            }
      }
    >
      <External css={{ opacity: 0 }} />
    </Navigation.Item>
  );
});

interface SidebarProps {
  open: boolean;
  permanent: boolean;
  onClose: () => void;
  minimized: boolean;
  onToggleMinimized: (minimize: boolean) => void;
  setExplorePuzzleDialogOpen: (open: boolean) => void;
}

// TODO Make this part of Item. Everything now needs its own `active` state if there is a popover.
// Or just remove this once Inbox is its own page... Not a long-term requirement.
const Item = ({ item, minimized }: { item: NavigationItemContent; minimized: boolean }) => {
  const [active, setActive] = useState(false);

  const content = (
    <Navigation.Item item={item} minimized={minimized} beta={item.beta} selected={active}>
      {item.isFeatureGated ? <Crown color="#666666" /> : item.suffix}
    </Navigation.Item>
  );

  if (item.Wrapper) {
    const Wrapper = item.Wrapper;
    return <Wrapper setActive={setActive}>{content}</Wrapper>;
  }

  return content;
};

const Sidebar = React.memo(function Sidebar({
  open,
  onClose,
  permanent,
  minimized,
  onToggleMinimized,
  setExplorePuzzleDialogOpen,
}: SidebarProps) {
  const { goHome } = useAppRouter();
  const { showUpgradeModal } = useMonetization();
  const { company, isOnFreeTrial, currentSubscription, isOnPaidPlan } = useActiveCompany<true>();

  const { hoverProps, isHovered: isHeaderHovered } = useHover({});

  const { navSections, rendered } = useNavSections({ minimized });

  const { daysLeft, daysLeftProgress } = useMemo(() => {
    const freeTrialEndDate = company.puzzlePlanSubscriptions.find(
      (subscription) => subscription.plan.name === "Premium Free Trial"
    )?.endDate;
    if (!freeTrialEndDate) return { daysLeft: 0, daysLeftProgress: 0 };
    const daysLeft = differenceInCalendarDays(
      parseDate(freeTrialEndDate).toDate("UTC"),
      now("UTC").toDate()
    );
    // dayLeft + 1 is to match Stripe free trial days
    const withExtraDay = daysLeft + 1;
    const daysLeftProgress = Math.round((withExtraDay / 30) * 100);
    return {
      daysLeft: Math.min(Math.abs(withExtraDay), 30),
      daysLeftProgress: Math.min(Math.abs(daysLeftProgress), 100),
    };
  }, [company]);

  const navMenu = useMemo(
    () =>
      navSections.map((s, i) => (
        <Navigation.Section key={i} title={s.title} minimized={minimized}>
          {s.items?.map((item, j) => (
            <Item key={j} item={item} minimized={minimized} />
          ))}
        </Navigation.Section>
      )),
    [navSections, minimized]
  );

  const showTrialInfo = useMemo(() => {
    // Trial components should render if:
    // 1. The user is on a Free Trial and
    // 2. The user hasn't selected the free plan
    // 3. The user hasn't switch to a paid plan
    return isOnFreeTrial && currentSubscription !== PricingPlanNames.FREE && !isOnPaidPlan;
  }, [isOnFreeTrial, isOnPaidPlan, currentSubscription]);

  const drawerContent = (
    <Content {...hoverProps}>
      {rendered}

      <HeaderContainer>
        <Header minimized={minimized}>
          <Minimizer
            minimized={minimized}
            onToggle={onToggleMinimized}
            hovered={permanent && isHeaderHovered}
          />

          <ProfileMenu minimized={minimized} />
        </Header>

        {!minimized && <CompanyMenu />}
      </HeaderContainer>

      <ScrollContainer>
        <NavigationContainer>{navMenu}</NavigationContainer>

        {showTrialInfo && daysLeft > 7 ? (
          minimized ? (
            <Box css={{ marginLeft: S["1"], marginBottom: S["2"] }}>
              <IconButton onClick={() => showUpgradeModal({redirectIfSubscribed: true})}>
                <SpinnerStatic />
              </IconButton>
            </Box>
          ) : (
            <FreeTrialProgress
              onClick={() => showUpgradeModal({redirectIfSubscribed: true})}
              css={{ "&::before": { width: `${daysLeftProgress}%` }, cursor: "pointer" }}
            >
              <div>
                <Text color="gray200">
                  {daysLeft} day{daysLeft !== 1 ? "s" : ""} left
                </Text>
                &nbsp;on free trial
              </div>
            </FreeTrialProgress>
          )
        ) : null}

        {showTrialInfo && daysLeft <= 7 && !minimized && (
          <TrialReminder daysLeft={daysLeft} minimized={minimized} />
        )}

        {!minimized && (
          <>
            <Button onClick={() => setExplorePuzzleDialogOpen(true)} variant="minimal">
              Explore Features
            </Button>
            <Button
              onClick={() => {
                // TODO: put in the new tour when ready
                goHome();
              }}
              variant="minimal"
            >
              Dashboard Tour
            </Button>
          </>
        )}
      </ScrollContainer>
    </Content>
  );

  const baseProps = {
    side: "left",
    minimized,
    secondary: undefined,
  } as const;

  if (permanent) {
    return <PermanentDrawer {...baseProps}>{drawerContent}</PermanentDrawer>;
  }

  return (
    <OverlayDrawer
      dark={false}
      {...baseProps}
      onOpenChange={(open: boolean) => !open && onClose()}
      open={open}
    >
      {drawerContent}
    </OverlayDrawer>
  );
});

export default Sidebar;
