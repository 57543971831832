import { AlertDialog, Button as ButtonOld, Stack, Tag } from "@puzzle/ui";
import { formatMoney } from "@puzzle/utils";

import React, { useEffect, useMemo } from "react";
import { StepChooser } from "./StepChooser";
import { PLANS, ADDONS, PlanTitle } from "./plans";
import Link from "components/common/Link";
import { AdditionalServices } from "./AdditionalServices";
import { SubscriptionCycleSwitch } from "./SubscriptionCycleSwitch";
import { YearlyDiscountBadge, BasicBadge } from "./Badges";
import { SubscriptionBillingCycle, Plan } from "../types";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { actualPrice, actualPriceAddon, joinWithCommasAnd } from "./utils";

import links from "lib/links";
import useMonetization from "../useMonetization";
import { AddOnBox } from "./AddOnBox";

import { S, Button, Separator, Text, color, Box, vars } from "ve";
import { useActiveCompany } from "components/companies";

const computeSubtotal = (
  selectedAddons: string[],
  cycle: SubscriptionBillingCycle,
  plan?: Plan,
  estimateMonthly?: boolean
) => {
  let total = 0;

  if (plan && plan.price && plan.priceYear) {
    total +=
      cycle === SubscriptionBillingCycle.Monthly
        ? plan.price
        : estimateMonthly
        ? Math.round(plan.priceYear / 12)
        : plan.priceYear;
  }

  for (let i = 0; i < selectedAddons.length; i++) {
    const selectedAddon = ADDONS.find((a) => a.id === selectedAddons[i]);
    if (selectedAddon) {
      const price =
        cycle === SubscriptionBillingCycle.Monthly
          ? selectedAddon.price
          : estimateMonthly
          ? Math.round(selectedAddon.priceYear / 12)
          : selectedAddon.priceYear;
      if (price) {
        total += price;
      }
    }
  }

  return total;
};

export const ReviewStep = () => {
  const [showAdditionalServices, setShowAdditionalServices] = React.useState(false);
  const {
    selectedPlan,
    selectedAddons,
    selectedCycle,
    disableAnnualPlan,
    loadingStripe,
    onCheckout,
    setBookkeepingEnabled,
    bookkeepingEnabled,
    isConfirmationModalVisible,
    hideAddonConfirmationModal,
    addonsForConfirmationModal,
    addItemsToStripeSubscription,
  } = useMonetization();
  const { currentSubscription, isOnPaidPlan } = useActiveCompany<true>();
  const showAddonPuzzleAI = isPosthogFeatureFlagEnabled(FeatureFlag.ShowAddonPuzzleAI);
  const allowYearlySubscription = !disableAnnualPlan && isPosthogFeatureFlagEnabled(FeatureFlag.SubscriptionYearly);

  useEffect(() => {
    if (selectedPlan) {
      Analytics.checkoutReviewScreenViewed({ plan: selectedPlan });
    }
  }, [selectedPlan]);

  const plan = useMemo(() => PLANS.find((t) => t.title === selectedPlan), [selectedPlan]);
  const price = actualPrice(selectedCycle || SubscriptionBillingCycle.Monthly, plan);
  const showDiscountBadge = selectedCycle === SubscriptionBillingCycle.Yearly && price;

  const subtotal = computeSubtotal(
    selectedAddons,
    selectedCycle,
    plan?.id !== currentSubscription ? plan : undefined
  );

  const monthlyEstimate = computeSubtotal(
    selectedAddons,
    selectedCycle,
    plan?.id !== currentSubscription ? plan : undefined,
    true
  );

  const isPlanCurrentSubscription = plan?.id === currentSubscription && isOnPaidPlan;

  return (
    <>
      <AlertDialog
        open={isConfirmationModalVisible}
        onOpenChange={(open) => {
          if (!open) hideAddonConfirmationModal();
        }}
        size="small"
      >
        <AlertDialog.Title showClose>
          Subscribe to {joinWithCommasAnd(addonsForConfirmationModal.map((addon) => addon.title))}
        </AlertDialog.Title>
        <AlertDialog.Body css={{ color: "$gray200" }}>
          Would you like to add{" "}
          {joinWithCommasAnd(addonsForConfirmationModal.map((addon) => addon.title))} to your
          existing subscription?
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <AlertDialog.CancelButton>No thanks</AlertDialog.CancelButton>
          <AlertDialog.ConfirmButton
            onClick={() => addItemsToStripeSubscription(addonsForConfirmationModal)}
            variant="primary"
          >
            Yes
          </AlertDialog.ConfirmButton>
        </AlertDialog.Footer>
      </AlertDialog>
      <Stack
        css={{
          margin: "$10 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          maxWidth: "1120px",
        }}
        gap="5"
      >
        <Stack
          css={{
            gap: "$4",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text variant="heading1" color="white">
            Review your plan & services
          </Text>
          <Text variant="bodyL" color="gray200">
            Confirm your plan and services selection before checking out
          </Text>
          <div>
            <StepChooser step={2} />
          </div>
          <Stack
            css={{
              flexDirection: "row",
              gap: "$4",
              display: "flex",
            }}
          >
            <Stack
              css={{
                flex: "1 0 800px",
                padding: "$5",
                borderRadius: "$3",
                border: "1px solid $white100",

                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%), #1A192A",
              }}
            >
              {allowYearlySubscription && (
                <>
                  <Box
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: `-${S["3"]}`,
                    }}
                  >
                    <Text variant="heading3" color="gray200">
                      Your billing cycle
                    </Text>
                    <SubscriptionCycleSwitch />
                  </Box>

                  <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
                </>
              )}
              <Text variant="heading3" color="gray200">
                Your plan
              </Text>
              <Stack
                css={{
                  background: "rgba(255, 255, 255, 0.04)",
                  borderRadius: "$2",
                  border: "1px solid $white100",
                  padding: "$2h",
                  gap: "$1",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text variant="heading2h" color="gray200">
                    <Stack
                      direction="horizontal"
                      css={{ display: "flex", gap: "$2", alignItems: "center" }}
                    >
                      {plan?.title}{" "}
                      {isPlanCurrentSubscription && (
                        <Tag variant="outlined" color="vivid">
                          Active subscription
                        </Tag>
                      )}
                    </Stack>
                  </Text>

                  {price ? (
                    <Box
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: S["2"],
                      }}
                    >
                      {!isPlanCurrentSubscription && (
                        <>
                          <Text variant="heading3" color="gray200">
                            {price} / month
                          </Text>

                          {showDiscountBadge && <YearlyDiscountBadge css={{ width: "50px" }} />}
                        </>
                      )}
                    </Box>
                  ) : (
                    <Text variant="heading3" color="gray200">
                      "Free"
                    </Text>
                  )}
                </Box>

                <Box css={{ display: "flex", flexDirection: "row", gap: S["1"] }}>
                  {plan?.badges.map((badge, i) => (
                    <BasicBadge text={badge} key={i} />
                  ))}
                </Box>
              </Stack>
              {showAddonPuzzleAI && selectedPlan !== PlanTitle.Advanced && (
                <>
                  <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
                  <Text variant="heading3" color="gray200">
                    Add-ons
                  </Text>
                  <Text variant="headingM" color="gray400">
                    Enhance your current subscription with select add-ons from the
                    <Text variant="headingM" color="gray200">
                      {" "}
                      Advanced{" "}
                    </Text>
                    plan. You can flexibly add or remove them anytime.
                  </Text>

                  {ADDONS.map((addon, index) => (
                    <AddOnBox key={index} {...addon} />
                  ))}
                </>
              )}
              <Separator css={{ marginTop: S.$4, marginBottom: S.$3 }} />
              <Text variant="heading3" color="gray200">
                Professional Accounting Services
              </Text>
              <div>
                <Text variant="headingM" color="gray400">
                  Don’t want to do it yourself? We will reach out to understand your needs and pair
                  you with the optimal accounting professional from our accounting partner network
                  to manage your Puzzle account and ensure you have a hands-off experience.{" "}
                </Text>
                <Link onClick={() => setShowAdditionalServices((prev) => !prev)}>
                  <Text color="blue500" variant="bodyM">
                    {showAdditionalServices ? "Show less" : "Show more"}
                  </Text>
                </Link>
              </div>

              <Box css={{ display: "flex", flexDirection: "row", gap: S["1"] }}>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={bookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setBookkeepingEnabled(true, { shallow: true })}
                >
                  Yes
                </Button>
                <Button
                  data-testid="bookkeeping-request-button-yes"
                  variant={!bookkeepingEnabled ? "primary" : "secondary"}
                  onClick={() => setBookkeepingEnabled(false, { shallow: true })}
                >
                  No
                </Button>
              </Box>

              {showAdditionalServices && <AdditionalServices />}
            </Stack>
            <Box
              css={{
                flex: "1 0 320px",
              }}
            >
              <Stack
                css={{
                  borderRadius: "$3",
                  padding: "$3",
                  border: "1px solid rgba(255, 255, 255, 0.12)",
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #1A192A",
                  gap: "$2h",
                }}
              >
                <Text color="gray200" variant="heading3">
                  Summary
                </Text>
                {!isPlanCurrentSubscription && (
                  <Box
                    data-testid={`summary-${plan?.id}-line`}
                    css={{
                      color: color.gray400,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text variant="bodyM" color="gray400">
                      {plan?.title} plan
                    </Text>
                    <Text variant="bodyM" color="gray400">
                      {price} / mo
                    </Text>
                  </Box>
                )}

                {selectedAddons.length > 0 && (
                  <Text variant="bodyXS" color="gray400">
                    ADD-ONS:
                  </Text>
                )}

                {selectedAddons.map((addon) => {
                  const selectedAddon = ADDONS.find((a) => a.id === addon);
                  return selectedAddon ? (
                    <Box
                      data-testid={`summary-${addon}-line`}
                      key={selectedAddon.id}
                      css={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text variant="bodyM" color="gray400">
                        &nbsp; &#x2022; {selectedAddon.title}
                      </Text>
                      <Text variant="bodyM" color="gray400">
                        {actualPriceAddon(
                          selectedCycle || SubscriptionBillingCycle.Monthly,
                          selectedAddon.price,
                          selectedAddon.priceYear
                        )}
                        {" / mo"}
                      </Text>
                    </Box>
                  ) : null;
                })}

                <Separator />
                {selectedCycle === SubscriptionBillingCycle.Yearly && (
                  <Box
                    data-testid="monthly-estimate"
                    css={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text variant="headingM" color="gray400">
                      Monthly estimate
                    </Text>
                    <Text variant="headingM" color="gray400">
                      {formatMoney(
                        {
                          currency: "USD",
                          amount: monthlyEstimate,
                        },
                        { truncateValue: false }
                      )}
                      {" / mo"}
                    </Text>
                  </Box>
                )}
                <Box
                  css={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text variant="headingM" color="gray50">
                    Total due
                  </Text>
                  <Text variant="headingM" color="gray50">
                    {formatMoney({ currency: "USD", amount: subtotal }, { truncateValue: false })}
                    {selectedCycle === SubscriptionBillingCycle.Yearly ? " / year" : " / mo"}
                  </Text>
                </Box>

                {showDiscountBadge && (
                  <Box
                    css={{
                      padding: `${S["1h"]} ${S["2"]}`,
                      borderRadius: vars.radii[2],
                      backgroundColor: color.greenBackground,
                    }}
                  >
                    <Text variant="bodyM" color="green600">
                      You are saving 15% with yearly subscriptions
                    </Text>
                  </Box>
                )}

                <ButtonOld variant="primary" fullWidth onClick={onCheckout} loading={loadingStripe}>
                  Continue
                </ButtonOld>
              </Stack>
              <Box
                css={{ display: "flex", flexDirection: "column", gap: S["2"], marginTop: S["2"] }}
              >
                <Link
                  href={links.faqPage}
                  css={{
                    textDecoration: "none !important",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text color="blue500" variant="bodyM">
                    FAQs
                  </Text>
                </Link>
                <Link>
                  <Text id="plan-review-lets-chat" color="blue500" variant="bodyM">
                    Contact sales
                  </Text>
                </Link>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
