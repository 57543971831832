import React, { useCallback } from "react";
import { Dialog, IconButton, Text, useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import useSelf from "components/users/useSelf";
import Analytics from "lib/analytics";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useMonetization from "../useMonetization";
import { AdditionalServices } from "graphql/types";
import links from "lib/links";
import { Close as CloseIcon } from "@puzzle/icons";
import { ADDONS, PLANS, PlanTitle } from "./plans";

import { PlanSelectStep } from "./PlanSelectStep";
import { ReviewStep } from "./ReviewStep";
import { AddOn } from "../types";
import { joinWithCommasAnd } from "./utils";
import { reportError } from "lib/errors";
import { IS_PROD } from "lib/config";

export const PricingModal = () => {
  const router = useRouter();
  const { toast } = useToasts();
  const { self } = useSelf();
  const { company: loadedCompany, membershipRole } = useActiveCompany<true>();
  const {
    isModalVisible,
    hideUpgradeModal,
    showUpgradeModal,
    showUpgradeModalQuery,
    setShowUpgradeModalQuery,
    selectedPlan,
    setSelectedPlan,
    setSelectedCycle,
    setBookkeepingEnabled,
    requestAdditionalServices,
  } = useMonetization();

  // For users who share the link with the query param, we want to show the modal
  useEffect(() => {
    if (!isModalVisible && showUpgradeModalQuery) {
      showUpgradeModal();
    }
  }, [showUpgradeModal, showUpgradeModalQuery, isModalVisible]);

  useEffect(() => {
    if (isModalVisible && self) {
      Analytics.paidTrialUpgradeModalViewed({
        companyId: loadedCompany.id,
        userId: self.id ?? undefined,
      });
    }
  }, [isModalVisible, loadedCompany.id, self]);

  // The idea behind this is to clear the query params and avoid
  // the url to be confusing mantaining state it shouldn't
  // Await is needed when running multiple query cleans
  // Shallow true is to avoid running NextJS SSR/SSG? functions
  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (!open) {
        await setShowUpgradeModalQuery(null, { shallow: true }); // needs to happen first so modal can close properly
        hideUpgradeModal();
        await setBookkeepingEnabled(null, { shallow: true });
        await setSelectedPlan(null, { shallow: true });
        await setSelectedCycle(null, { shallow: true });
      }
    },
    [
      hideUpgradeModal,
      setShowUpgradeModalQuery,
      setBookkeepingEnabled,
      setSelectedPlan,
      setSelectedCycle,
    ]
  );

  useEffect(() => {
    if (selectedPlan === PlanTitle.Formation) {
      handleOpenChange(false);
      toast({
        duration: 10000,
        message: <>You are now subscribed to Formation plan</>,
        title: "Subscription updated",
      });
    }
  }, [selectedPlan, handleOpenChange, toast]);

  useEffect(() => {
    /**
     * If paymentSuccess query param is present and its true and plan its present
     * find what plan it its and show a toast for the user.
     */
    if (router.query.paymentSuccess && router.query.paymentSuccess === "true") {
      const subscriptionCycleMonthly = router.query.cycle === "monthly";
      const planSelected = PLANS.find((t) => t.title === router.query.plan);
      const addonsSelected =
        typeof router.query.addon === "undefined"
          ? []
          : Array.isArray(router.query.addon)
          ? router.query.addon
          : [router.query.addon];

      const mappedAddons = addonsSelected.reduce<AddOn[]>((result, newAddon) => {
        const addon = ADDONS.find((a) => a.title === newAddon);
        if (addon) {
          result.push(addon);
        }
        return result;
      }, []);

      /**
       * If bookkeeping is requested, then call requestAdditionalServices
       * that will trigger a new inbox task to complete a survey
       */
      if (router.query.bookkeeping && router.query.bookkeeping === "true") {
        requestAdditionalServices([AdditionalServices.Bookkeeping]);
      }
      if (planSelected || mappedAddons.length > 0) {
        toast({
          duration: 10000,
          message: (
            <>
              You are now subscribed to{" "}
              {planSelected && (
                <>
                  <Text color={planSelected.titleColor}>{planSelected?.title}</Text> plan
                </>
              )}
              {mappedAddons.length > 0 ? (
                <>
                  {planSelected ? " with " : ""}{" "}
                  {joinWithCommasAnd(mappedAddons.map((addon) => addon.title))}
                </>
              ) : (
                ""
              )}{" "}
              {subscriptionCycleMonthly ? "monthly" : "yearly"}.
            </>
          ),
          onAction: () => router.push(links.stripeCustomerPortal),
          actionText: "Manage subscription",
          title: "Subscription updated",
        });
      }

      // Notify Google of the subscription for ad targeting purposes
      if (planSelected?.price && IS_PROD) {
        try {
          gtag("event", "conversion", {
            send_to: "AW-11179879045/G2yfCImNucEZEIXV_dIp",
            value: planSelected.price,
            currency: "USD",
            event_callback: () => undefined,
          });
        } catch (error: any) {
          reportError(error);
        }
      }
    }
  }, [router.query.paymentSuccess, router.query.plan, toast, router, requestAdditionalServices]);

  return (
    <>
      <Dialog
        size="fullScreen"
        data-testid="upgrade-modal"
        open={isModalVisible}
        onOpenChange={handleOpenChange}
      >
        <Dialog.Body
          css={{
            background:
              "radial-gradient(59.63% 59.63% at 50% 0%, rgba(106, 75, 194, 0.72) 0%, rgba(23, 22, 36, 0) 100%), #000211;",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <Dialog.Close asChild>
            <IconButton
              css={{
                position: "fixed",
                right: "$2",
                top: "$2",
                width: "32px",
                height: "32px",
                justifyContent: "center",
                display: "block",
                lineHeight: 0,
              }}
            >
              <CloseIcon color="currentColor" />
            </IconButton>
          </Dialog.Close>
          {selectedPlan && selectedPlan !== PlanTitle.Formation ? (
            <ReviewStep />
          ) : (
            <PlanSelectStep/>
          )}
        </Dialog.Body>
      </Dialog>
    </>
  );
};
