import React, { useMemo, useState } from "react";
import { compact } from "lodash";
import { styled } from "@puzzle/ui";
import {
  Dashboard,
  Folder,
  Burn,
  Revenue,
  PeopleCard,
  Dollar,
  Plug,
  Reports,
  NavChecklist,
  NavInbox,
  NavAccountant,
  Vendors,
  Settings,
  Confirmed,
  CustomerMenuIcon
} from "@puzzle/icons";
import { Route } from "lib/routes";
import config from "lib/config";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

import { useActiveCompany, PricingFeatures } from "components/companies";
import { NavigationSectionContent } from "./Navigation/types";
import IntegrationStatusIcon from "./icons/IntegrationStatusIcon";
import { useInboxStore } from "components/dashboard/Inbox/InboxContext";
import { AskAIDrawer } from "components/AI/AskAIDrawer";
import { BookkeeperPlan } from "graphql/types";
import useRouteAccess from "lib/useRouteAccess";
import { usePOMChecklist } from "components/dashboard/Dashboard/PostOnboardModalV3/usePOMChecklist";
import { usePostOnboardModalStore } from "components/dashboard/Dashboard/PostOnboardModalV3/postOnboardModalStore";
import { differenceInDays } from "date-fns";
import { getLocalTimeZone, parseAbsolute, parseDate } from "@puzzle/utils";

const disabledMessage = "Please wait until your data has been ingested to access this page";

const CountBadge = styled("span", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  width: "20px",
  height: "20px",
  padding: "$0h $1",
  gap: "10px",

  background: "$elephant300",
  borderRadius: "6px",
  fontWeight: "$bold",
  textVariant: "$bodyXS",
  lineHeight: "14px",
  color: "$gray200",

  variants: {
    variant: {
      widthless: {
        width: "auto",
      },
    },
  },
});

const NotificationBadge = () => {
  const numberOfUpdates = useInboxStore("updates", (state) => state.metadata.unseen_count);
  const numberOfTasks = useInboxStore("tasks", (state) => state.metadata.unseen_count);

  const total = numberOfUpdates + numberOfTasks;
  return total > 0 ? <CountBadge>{total}</CountBadge> : null;
};

const GetSetupBadge = () => {
  const { pomProgress } = usePOMChecklist();
  return <CountBadge variant="widthless">{pomProgress}%</CountBadge>;
};

export default function useNavSections({ minimized }: { minimized: boolean }) {
  const { initialIngestCompleted, company, pricePlanFeatureEnabled, membership } =
    useActiveCompany<true>();
  const { showPostOnboardModal } = usePostOnboardModalStore();
  const { pomProgress, loading: loadingPOMTransactions } = usePOMChecklist();
  const { hasAccess } = useRouteAccess();

  const isOwner = useMemo(() => {
    return membership?.isOwner || false;
  }, [membership]);

  const [askAIIsOpen, setAskAI] = useState(false);
  const timeZone = company.timeZone || getLocalTimeZone();
  const onboardingdDate = company.onboardingCompletedDate
    ? parseDate(company.onboardingCompletedDate).toDate(timeZone)
    : parseAbsolute(company.createdAt, timeZone).toDate();

  // Post onboarding modal progress < 100%
  // we are not loading transactions
  // and the difference in days between today and (onboardingCompletedDate or company been created)
  // shouldn't exceed 7 days
  // only to owners
  const showGetStartedButton =
    pomProgress < 100 &&
    !loadingPOMTransactions &&
    differenceInDays(new Date(), onboardingdDate) < 7 &&
    isOwner;

  const navSections = useMemo<NavigationSectionContent[]>(() => {
    return compact([
      showGetStartedButton && {
        title: "",
        items: [
          {
            title: "Get setup",
            icon: <Confirmed color="gray300" />,
            disabled: false,
            suffix: <GetSetupBadge />,
            path: Route.home,
            onClick: () => {
              showPostOnboardModal();
            },
          },
        ],
      },
      hasAccess(Route.inbox) && {
        title: "",
        items: [
          {
            title: "Inbox",
            icon: <NavInbox />,
            disabled: false,
            suffix: <NotificationBadge />,
            path: Route.inbox,
          },
        ],
      },
      {
        title: "",
        items: compact([
          {
            title: "Dashboard",
            path: Route.home,
            icon: <Dashboard />,
            disabled: false,
            // This doesn't cache between pages and I think we wanted it disabled?
            // suffix: <DashboardStatusIcon minimized={minimized} />,
          },
          hasAccess(Route.checklist) && {
            title: "Monthly checklist",
            path: Route.checklist,
            icon: <NavChecklist />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          hasAccess(Route.reports) && {
            title: "Reports",
            path: Route.reports,
            icon: <Reports />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          hasAccess(Route.ledgerReconciliations) && {
            title: "Accounting",
            path: Route.ledgerReconciliations,
            matchPath: Route.accounting,
            icon: <Folder />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          hasAccess(Route.askAccountant) &&
            company?.bookkeeperPlan !== BookkeeperPlan.HasBookkeeper && {
              title: "Ask an accountant",
              path: Route.askAccountant,
              matchPath: Route.askAccountant,
              icon: <NavAccountant />,
              disabled: !initialIngestCompleted,
              disabledMessage,
              onClick: () => {
                Analytics.askAnAccountantButtonClicked();
              },
              isFeatureGated: !pricePlanFeatureEnabled.has(PricingFeatures.ask_accountant),
            },
          config.IS_LOCAL_DEVELOPMENT && {
            title: "Ask an AI",
            icon: <NavAccountant />,
            disabled: !initialIngestCompleted,
            disabledMessage,
            beta: "New",
            onClick: () => {
              setAskAI(true);
            },
          },
        ]),
      },
      (hasAccess(Route.transactions) ||
        hasAccess(Route.spending) ||
        hasAccess(Route.vendors) ||
        hasAccess(Route.revenue) ||
        hasAccess(Route.people)) && {
        title: "Financial Exploration",
        items: compact([
          hasAccess(Route.transactions) && {
            title: "Transactions",
            path: Route.transactions,
            icon: <Dollar />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          hasAccess(Route.spending) && {
            title: "Spending",
            path: Route.spending,
            icon: <Burn />,
            disabled: !initialIngestCompleted,
            disabledMessage,
            isFeatureGated: !pricePlanFeatureEnabled.has(
              PricingFeatures.spend_explorer_vendor_insights
            ),
          },
          hasAccess(Route.vendors) && {
            title: "Vendors",
            path: Route.vendors,
            icon: <Vendors />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          hasAccess(Route.revenue) && {
            title: "Revenue",
            path: Route.revenue,
            icon: <Revenue />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
          // {
          //   title: "Burn",
          //   key: "Burn",
          //   path: Route.burn,
          //   icon: <Burn />,
          //   disabled: !initialIngestCompleted,
          //   disabledMessage,
          // },
          // {
          //   title: "Categorization Rules",
          //   path: Route.rules,
          //   icon: <Dollar />,
          // },
          // {
          //   title: "Expenses",
          //   path: Route.expenses,
          //   icon: <CreditCard />,
          //   disabled: true,
          // },
          hasAccess(Route.people) && {
            title: "People",
            path: Route.people,
            icon: <PeopleCard />,
            disabled: !initialIngestCompleted,
            disabledMessage,
            isFeatureGated: !pricePlanFeatureEnabled.has(PricingFeatures.people_insights),
          },
          hasAccess(Route.customers) && isPosthogFeatureFlagEnabled(FeatureFlag.CustomersPage) && {
            title: "Customers",
            path: Route.customers,
            icon: <CustomerMenuIcon />,
            disabled: !initialIngestCompleted,
            disabledMessage,
          },
        ]),
      },
      (hasAccess(Route.integrations) || hasAccess(Route.companySettings)) && {
        title: "",
        items: compact([
          hasAccess(Route.integrations) &&
            !company.features.hideIntegrationsPage && {
              title: "Integrations",
              path: Route.integrations,
              icon: <Plug />,
              disabled: false,
              suffix: <IntegrationStatusIcon minimized={minimized} />,
            },
          hasAccess(Route.companySettings) && {
            title: "Settings",
            path: Route.companySettings,
            matchPath: Route.settings,
            icon: <Settings />,
            disabled: false,
          },
        ]),
      },
    ]);
  }, [
    initialIngestCompleted,
    pricePlanFeatureEnabled,
    company.features.hideIntegrationsPage,
    minimized,
    company.bookkeeperPlan,
    hasAccess,
    showGetStartedButton,
    showPostOnboardModal,
  ]);

  // To help support drawers and whatnot.. hack.....
  const rendered = (
    <>
      <AskAIDrawer onOpenChange={() => setAskAI(!askAIIsOpen)} open={askAIIsOpen} />
    </>
  );

  return { navSections, rendered };
}
